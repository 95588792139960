/* eslint-disable react/self-closing-comp */
export const SocialButtons = [
  {
    id: 2,
    icon: <i className="fab fa-telegram"></i>,
    link: 'https://t.me/onkologiya_urganch'
  },
  {
    id: 3,
    icon: <i className="fab fa-instagram"></i>,
    link: 'https://instagram.com/onkologiya'
  },
  {
    id: 4,
    icon: <i className="fab fa-youtube"></i>,
    link: 'https://www.youtube.com/channel/UCo8Enhdf8Md5gjM_QFEt8jw'
  }
];
