import { Link } from 'react-router-dom';
// material
import { Button, Box } from '@mui/material';
// components
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
//
import { logout } from '../../redux/actions/authActions';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(logout());
  };
  return (
    <>
      <Box sx={{ p: 2, pt: 1.5 }}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleClick}>
            Chiqish <Icon icon="bx:exit" />
          </Button>
        </Link>
      </Box>
    </>
  );
}
