/* eslint-disable react/jsx-boolean-value */
import { Carousel } from '3d-react-carousal';
import classes from './Carousel.module.css';

const slides = [
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/5iXenG3UEf8"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    key="12"
  />,
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/O14QW4jt228"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    key="1"
  />,
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/a7vpGOwQMhE"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    key="15"
  />,
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/gboiNEILcdk?start=2"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    key="51"
  />,

  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/JJ10aG4Rij4"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    key="41"
  />
];
const callback = (index) => {
  console.log('callback', index);
};
export default function MyCarousel() {
  return (
    <div className={classes.bgImage}>
      <div style={{ width: '100%' }}>
        <Carousel slides={slides} autoplay={false} interval={5000} onSlideChange={callback} />
      </div>
    </div>
  );
}
