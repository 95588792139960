// ----------------------------------------------------------------------
import img from '../img/cc.png';

const account = {
  displayName: "Faxriddin",
  photoURL: `${img}`,
  role: 'Admin'
};

export const account2 = {
  displayName: "Faxriddin",
  photoURL: `${img}`,
  role: 'Admin'
};

export default account;
